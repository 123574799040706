import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ClipPath from "components/clip-path";
import EarnComponent from "./earn";
import { useNavigate } from "react-router-dom";

const Earn = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));
  const navigate = useNavigate();
  return (
    <ClipPath
      id='shape-markets'
      children={<EarnComponent largeScreen={largeScreen} navigate={navigate} />}
    />
  );
};

export default Earn;
