import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import RegisterComponent from "./register";

const Register = () => {
  const [showPassword, setShowPassword] = useState(true);
  const navigate = useNavigate();

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <RegisterComponent
      handleClickShowPassword={handleClickShowPassword}
      showPassword={showPassword}
      largeScreen={largeScreen}
      navigate={navigate}
    />
  );
};

export default Register;
