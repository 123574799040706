import {
  Modal,
  Divider,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  Stack,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "24px 4px",
  boxShadow: 24,
  p: 3,
};

const EthereumModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={modalStyle}>
        <Grid container spacing={8}>
          <Grid item xs={7}>
            <Stack direction='row' alignItems='center' spacing={1} mb={2}>
              <Typography variant='h6' component='h2'>
                ETH 2.0 Staking
              </Typography>
            </Stack>
            <Divider />

            <Stack
              direction='row'
              alignItems='flex-end'
              justifyContent='space-between'>
              <Typography variant='body2' mt={4} component='h2'>
                {`Stake amount`}
              </Typography>

              <Typography variant='body2' component='h2'>
                <span style={{ fontWeight: "bold" }}>Available:</span>
                {` 0.0 `}
              </Typography>
            </Stack>

            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              justifyContent='space-between'>
              <TextField defaultValue='$0' size='small' fullWidth />
            </Stack>

            <Stack
              direction='column'
              alignItems='center'
              justifyContent='center'
              my={2}>
              <ArrowDownwardIcon color='primary' fontSize='large' />
            </Stack>
            <Typography variant='body2'>Distribute</Typography>
            <TextField defaultValue='0 BETH' size='small' fullWidth />

            <Typography variant='body2' component='h2' mt={4}>
              {`Amount Limits`}
            </Typography>

            <Stack direction='column'>
              <Typography variant='body2'>
                <span style={{ fontWeight: "bold" }}>
                  Daily available limit:
                </span>
                {` 49.999888 ETH`}
              </Typography>
              <Typography variant='body2'>
                <span style={{ fontWeight: "bold" }}>
                  Total Personal Staking quatos:
                </span>
                {` 200.000 ETH`}
              </Typography>
            </Stack>

            <Stack direction='column' spacing={2} mt={6}>
              <Typography variant='h6' component='h2'>
                {`Note`}
              </Typography>
              <Typography variant='body2' component='h2' mt={2}>
                {`The available balance in your spot wallet will be used daily at 02:00 and 20:00 to subscribe to Flexible`}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              justifyContent='space-between'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='body2'>Summary</Typography>
                <SummarizeIcon fontSize='small' color='primary' />
              </Stack>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>

            <Stack mb={2}>
              <Stepper orientation='vertical'>
                <Step active>
                  <StepLabel>Stake Date</StepLabel>
                </Step>
                <Step active>
                  <StepLabel>Value Date</StepLabel>
                </Step>
                <Step active>
                  <StepLabel>Interest Distribution Date</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Redemption Date</StepLabel>
                </Step>
              </Stepper>
            </Stack>
            <Divider />

            <List>
              <ListItem>
                <ListItemText>
                  1. ETH 2.0 Staking interest is calculated and distributed
                  according to your BETH holdings in the spot wallet
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  2. The ETH 2.0 Staking APR is dynamically changing, following
                  the onchain Ethereum Staking rewards.
                </ListItemText>
              </ListItem>
            </List>
            <Box
              sx={{
                border: "1px solid #662ad1",
                p: 1,
                borderRadius: "4px",
                my: 2,
              }}>
              <Stack direction='row' alignItems='flex-start' spacing={1}>
                <InfoIcon color='primary' fontSize='small' />
                <Typography variant='body2'>
                  APR does not mean the actual or predicted returns in fiat
                  currency
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Checkbox defaultChecked size='small' sx={{ p: 0 }} />
                <Typography variant='body2'>
                  I have read and I agree to ...
                </Typography>
              </Stack>
            </Box>

            <Stack>
              <Button variant='contained' fullWidth>
                Confirm
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EthereumModal;
