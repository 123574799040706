export const earnfaq = [
  {
    id: 1,
    question: "What is CryptoStein Earning?",
    answer:
      "CryptoStein Earn is where you can find and see all the earning possibilities. You can buy crypto, hold and earn, or you can also earn on the cryptos that you hold.",
  },
  {
    id: 2,
    question: "How does CryptoStein Earn work?",
    answer:
      "Using CryptoStein Earn is easy. Choose from the available cryptocurrencies and subscribe for the product that suits your needs and start earning on that",
  },
  {
    id: 3,
    question: "Which cryptocurrencies are supported?",
    answer: "You can choose between the dozens of digital assets we have",
  },
  {
    id: 4,
    question: "Am I eligible?",
    answer:
      "As long as you have completed all the necessary verification checks and you have the minimum amount of cryptocurrency, then you are eligible.",
  },

  {
    id: 5,
    question: "How do I start earning?",
    answer:
      "You choose the product you want and then you can see your earnings on your dashboard. Each  product has its own terms and conditions.",
  },
  {
    id: 6,
    question: "Is the  earning value fixed?",
    answer: "The earning value will fluctuate based on the market conditions.",
  },
];

export const ethfaq = [
  {
    id: 1,
    question: "Is ETH good for staking?",
    answer:
      "Staking ETH is an easy way to earn yield on the Ether you already own. If you like to buy and hold ETH, staking takes the effort out of earning on it. However, if you are someone who trades crypto frequently, staking may not be a suitable option for you.",
  },
  {
    id: 2,
    question: "Is staking more profitable than mining?",
    answer:
      "Staking could be more profitable for the average user because the only thing required is money. Mining requires special hardware, access to cheap electricity, and some technical knowledge. The value of the coin in question is also important.",
  },
  {
    id: 3,
    question: "How do I start staking Ethereum?",
    answer:
      "Staking Ethereum requires you to purchase Ether tokens. You can buy Ethereum tokens directly on Coinbase, making it easy for you to buy and stake your Ethereum tokens all in one place. You can purchase Ether tokens in a similar way to stocks: as a market order or a limit order.",
  },
  {
    id: 4,
    question: "How much ETH do you get from staking?",
    answer:
      "The benefits of stacking ETH:  Ethereum is a programmable blockchain that gives you access to various decentralized finance services, games and applications through smart contracts. Staking with Kiln offers an average return of 7%. Staking with Lido offers an average return of 4%.",
  },

  {
    id: 5,
    question: "Can you lose staked Ethereum?",
    answer:
      "An important risk to be aware of is the possibility of losing your staked assets due to slashing. Slashing is a penalty enforced at the protocol level associated with a network or validator failure",
  },
  {
    id: 6,
    question: "Should I stake my ETH on Coinbase?",
    answer:
      "In general, it is safe to stake your ETH on Coinbase. However, what you need to be aware of, is the potential for slashing",
  },
  {
    id: 7,
    question: "Can you sell staked Ethereum?",
    answer:
      "Those who want to trade staked ETH can already do so via liquidity products, and those who are running their validators are unlikely to sell on launch. An investor who has set up a node and has 32 ETH staked over the past few years is invested in Ethereum's future.",
  },
  {
    id: 8,
    question: "How much Ethereum do you need to start staking?",
    answer: "32 ETH",
  },
  {
    id: 9,
    question: "Does staking ETH cause taxes?",
    answer:
      "ETH staking rewards will be taxed as income. The question is when that taxable event will occur—when they are earned or when they are unlocked.",
  },
  {
    id: 10,
    question: "Do you have to pay taxes on Ethereum if you don't cash out?",
    answer:
      "Any time you purchase an NFT using a cryptocurrency like Ethereum or sell an NFT, you'll be subject to capital gain taxes. The amount you'll owe will depend on how long you held the NFT and whether you made a profit",
  },
  {
    id: 11,
    question: "Can you cash out ethereum to your bank account?",
    answer:
      "You can use a crypto exchange to cash out Ethereum fairly easily. You will need to connect an existing bank account and have your details verified. Once you are registered, cashing out is as easy as placing an order on the platform. Cashing out can sometimes take a while if you need to wait for your order to be filled",
  },
  {
    id: 12,
    question: "How much can you earn staking 32 ETH?",
    answer:
      "The newly-'Merged' ETH Blockchain requires each participant to stake a minimum of 32 ETH in order to be eligible for staking rewards and interest. The interest rate for staking varies according to the Blockchain's activities, and as of September 15, 2022, resides at 7 per cent annual percentage yield (APY)",
  },
  {
    id: 13,
    question: "Why do you need 32 ETH to stake?",
    answer:
      "To become a validator on Ethereum, users must invest 32 ETH. Validators are assigned to produce blocks at random and are accountable for double-checking and confirming any blocks they do not make. The stake of the user is also used to incentivize positive validator activity.",
  },
  {
    id: 14,
    question: "When can I sell my staked ETH?",
    answer:
      "StETh holders won't be able to redeem their tokens for ether until six to 12 months after an event known as the “merge,” which will complete Ethereum's transition from proof of work to proof of stake.",
  },
  {
    id: 15,
    question: "How long should I hold Ethereum?",
    answer:
      "For 2022-3 in general, we think recession risks pose a risk to ETH and so now might not be the best time to buy ethereum if you have a medium-term outlook. We think ethereum is a good long-term investment for the next one to three years and are bullish overall",
  },
  {
    id: 16,
    question: "What happens to my Ethereum when 2.0 comes out?",
    answer:
      "Your ETH will stay the same in the days before the Merge. The ETH holders who are interested only in holding, trading, or using their ETH on decentralized applications (dapps) do not have to actively do anything to prepare for the Merge",
  },
  {
    id: 17,
    question: "Is it better to buy ETH or ETH2?",
    answer:
      "Unlike the classic Ethereum, which could handle only 15 transactions per second, Ethereum 2.0 is much more efficient, completing up to 100,000 transactions each second. To give you a better idea, think of the older Ethereum blockchain as a very busy highway with just one lan",
  },
  {
    id: 18,
    question: "Is it smart to invest in ETH right now?",
    answer:
      "For 2022-3 in general, we think recession risks pose a risk to ETH and so now might not be the best time to buy ethereum if you have a medium-term outlook. We think ethereum is a good long-term investment for the next one to three years and are bullish overall. That means we expect prices to rise in the long term.",
  },
];

export const defifaq = [
  {
    id: 1,
    question: "What is a DeFi staking?",
    answer:
      "DeFi staking is a process that requires users to lock a certain amount of native tokens. This enables them to contribute to a PoS network. The PoS mechanism relies only upon validators having to stake in the chain. Thus, users become validators soon after the locking process.",
  },
  {
    id: 2,
    question: "Why is DeFi staking risky?",
    answer:
      "DeFi staking is more attractive to investors who can benefit from higher rewards than a traditional savings account. Yet, it comes with higher risks coupled with more considerable challenges the crypto markets unveil, such as the well-known volatility across the board and network security of novel blockchains.",
  },
  {
    id: 3,
    question: "Can you stake crypto DeFi?",
    answer:
      "This means locking fungible and non-fungible tokens into smart contracts, and users can earn rewards for performing their duties in exchange for staking crypto assets. DeFi staking can also be viewed from a wider perspective and includes all types of DeFi activities that require a temporary commitment to crypto assets.",
  },
  {
    id: 4,
    question: "Is DeFi staking risk free?",
    answer:
      "However, staking is not without risk. You'll earn rewards in crypto, a volatile asset. Sometimes, you have to lock up your crypto for a set period of time. And there is a chance that you could lose some of the cryptocurrency you've staked as a penalty if the system doesn't work as expected.",
  },

  {
    id: 5,
    question: "How long does DeFi staking last?",
    answer:
      "Currently, the lock-up period for regular products is 1 day. Funds will be unlocked and returned to your account on T + 1. However, as we add support for more types of products, the lock-up periods will vary.",
  },
  {
    id: 6,
    question: "Can you lose money in DeFi?",
    answer:
      "Failed transactions. Failed transactions are yet another way to lose money while swapping in DeFi. Many failed transactions are caused by the token rate dropping below the allotted slippage tolerance for a swap. A transaction can also fail if it was sent with too little gas.",
  },
  {
    id: 7,
    question: "How can I get passive income from DeFi?",
    answer:
      "The simplest way to earn a passive income through DeFi is to deposit your cryptocurrency onto a platform or protocol that will pay you an APY (annual percentage yield) for it.",
  },
  {
    id: 8,
    question: "Can you make money with staking?",
    answer:
      "With staking, you can put your digital assets to work and earn passive income without selling them. In some ways, staking is similar to depositing cash in a high-yield savings account. Banks lend out your deposits, and you earn interest on your account balance.",
  },
  {
    id: 9,
    question: "How do I start earning and stake my CRO on DeFi Wallet?",
    answer: "Answer based on our system.",
  },

  {
    id: 10,
    question: "Is DeFi the future?",
    answer:
      "Unlike traditional financial institutions, DeFi also allows you to remain in custody of your financial assets. This is a very modern and decentralized way of doing things. DeFi technology is the future since it brings solutions to traditional financial problems",
  },
  {
    id: 11,
    question: "Can you get rich from staking crypto?",
    answer:
      "The primary benefit of staking is that you earn more crypto, and interest rates can be very generous. In some cases, you can earn more than 10% or 20% per year. It's potentially a very profitable way to invest your money. And, the only thing you need is crypto that uses the proof-of-stake model.",
  },
  {
    id: 12,
    question: "Which crypto gives the highest staking?",
    answer:
      "The cryptocurrencies with the highest staking market cap include ETH, SOL and ADA, in which the typical annual yield is around 4% to 5%. Note rewards on the Ethereum network are typically locked up until the Ethereum 2.0 network is complete. Also of note, more than 10% of Ethereum is staked.",
  },
  {
    id: 13,
    question: "Which is better: staking or crypto?",
    answer:
      "You can use certain cryptocurrencies to make money by staking or lending. Staking lets you earn rewards for verifying transactions, while lending lets you collect interest from borrowers",
  },
  {
    id: 14,
    question: "Is staking good for the long term?",
    answer:
      "For long-term investors, staking can be a smart way to increase your earnings. If you're planning on keeping your money invested for many years, you might as well put your investments to work during that time. The key to successful staking, however, is to choose the right cryptocurrency",
  },
  {
    id: 15,
    question: "What happens after the staking period ends?",
    answer:
      "After the 180-days staking period is completed, you'll be able to unlock your CRO. Simply go to the CRO wallet in your App and tap the “Unstake” button. Note, that by unlocking CRO you will be losing a number of wallet benefits that come with CRO staking, for example: Purchase Rebates",
  },
  {
    id: 16,
    question: "Is staking always profitable?",
    answer:
      "Yes. Staking your cryptocurrencies is undoubtedly profitable. When you stake tokens within smart contracts, you get access to various levels of features and participation rights across the staking platform. Then, these staked tokens are put to work for validating transactions to earn you rewards.",
  },
];
