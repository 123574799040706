import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#662ad1",
      light: "#e0d3f8",
    },
    secondary: {
      main: "#eaecef",
      light: "#E0D4F7",
    },
    background: {
      default: "#f0eafb",
      paper: "#fff",
    },
    divider: "#5e6673",
  },
  typography: {
    fontFamily: "",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#181A20",
          zIndex: "10000",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: "space-between",
          height: "65px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontWeight: "400",
          fontSize: "15px",
          color: "#eaecef",
          "&:hover": {
            background: "linear-gradient(to right, #662ad1 , #9f77e9)",
            backgroundClip: "text",
            textFillColor: "transparent",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: "linear-gradient(to right, #662ad1 , #9f77e9)",
            backgroundClip: "text",
            textFillColor: "transparent",
          },
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: "600",
          fontSize: "14px",
        },
        secondary: {
          fontWeight: "600",
          fontSize: "14px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          "&.MuiButton-contained": {
            backgroundImage: "linear-gradient(to right, #662ad1 , #9f77e9)",
            color: "#fff",
            borderRadius: "24px 4px",
            padding: "14px 32px",
            fontWeight: "600",
          },
          "&.MuiButton-text": {
            fontWeight: "600",
            "&:hover": {
              background: "linear-gradient(to right, #662ad1 , #9f77e9)",
              backgroundClip: "text",
              textFillColor: "transparent",
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiDrawer-paper": {
            backgroundColor: "#181A20",
            width: "300px",
            padding: "5rem 1rem",
          },
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#662ad1",
    },
    secondary: {
      main: "#eaecef",
      light: "#181A20",
    },
    background: {
      default: "#0B0E11",
      paper: "#22252a",
    },

    divider: "#5e6673",
  },
  typography: {
    fontFamily: "",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#0b0e11",
          zIndex: "10000",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: "space-between",
          height: "65px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          fontWeight: "400",
          fontSize: "15px",
          color: "#eaecef",
          "&:hover": {
            background: "linear-gradient(to right, #662ad1 , #9f77e9)",
            backgroundClip: "text",
            textFillColor: "transparent",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: "linear-gradient(to right, #662ad1 , #9f77e9)",
            backgroundClip: "text",
            textFillColor: "transparent",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: "600",
          fontSize: "14px",
        },
        secondary: {
          fontWeight: "600",
          fontSize: "14px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          "&.MuiButton-contained": {
            backgroundImage: "linear-gradient(to right, #662ad1 , #9f77e9)",
            color: "#fff",
            borderRadius: "24px 4px",
            padding: "14px 32px",
            fontWeight: "600",
          },
          "&.MuiButton-text": {
            fontWeight: "600",
            "&:hover": {
              background: "linear-gradient(to right, #662ad1 , #9f77e9)",
              backgroundClip: "text",
              textFillColor: "transparent",
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiDrawer-paper": {
            backgroundColor: "#000000",
            width: "300px",
            padding: "5rem 1rem",
          },
        },
      },
    },
  },
});

export const light = responsiveFontSizes(lightTheme);
export const dark = responsiveFontSizes(darkTheme);
