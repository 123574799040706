import {
  Modal,
  Divider,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  Stack,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CloseIcon from "@mui/icons-material/Close";

import Image from "mui-image";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "24px 4px",
  boxShadow: 24,
  p: 3,
};

const DefiModal = ({ open, currencey, setOpen, handleSubscribeModal }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={modalStyle}>
        <Grid container spacing={8}>
          <Grid item xs={7}>
            <Stack direction='row' alignItems='center' spacing={1} mb={2}>
              <Typography variant='h6' component='h2'>
                Defi Staking
              </Typography>

              <Image src={currencey[0]?.image} duration={0} width='30px' />

              <Typography
                variant='h6'
                component='h2'
                sx={{ textTransform: "uppercase" }}>
                {currencey[0]?.id}
              </Typography>
            </Stack>
            <Divider />
            <Typography variant='body2' component='h2' mt={2}>
              {`Type`}
            </Typography>
            <Box
              sx={{
                border: "1px solid #662ad1",
                p: 1,
                width: "100%",
                borderRadius: "4px",
                mt: 1,
                textAlign: "center",
              }}>
              <Typography variant='body2' component='h2'>
                Flexible
              </Typography>
            </Box>

            <Typography variant='body2' component='h2' mt={4}>
              {`Lock Amount`}
            </Typography>

            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              justifyContent='space-between'>
              <TextField defaultValue='$520.0008703' size='small' />
              <Image src={currencey[0]?.image} duration={0} width='40px' />
              <Typography variant='body1' sx={{ textTransform: "uppercase" }}>
                {currencey[0]?.id}
              </Typography>
            </Stack>

            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              spacing={2}>
              <Typography
                variant='body2'
                component='h2'
                sx={{ fontSize: "12px" }}>
                <span style={{ fontWeight: "bold" }}>Available amount:</span>
                {` 520.8 ${currencey[0]?.id} `}
              </Typography>
            </Stack>

            <Typography variant='body2' component='h2' mt={4} mb={2}>
              {`Locked Amount Limitation`}
            </Typography>

            <Stack direction='column' spacing={2}>
              <Typography variant='body2'>
                <span style={{ fontWeight: "bold" }}>Minimum:</span>
                {` 0.1 ${currencey[0]?.id}`}
              </Typography>
              <Typography variant='body2'>
                <span style={{ fontWeight: "bold" }}>Available Quota:</span>
                {` 200.000 ${currencey[0]?.id}`}
              </Typography>
              <Typography variant='body2'>
                <span style={{ fontWeight: "bold" }}>
                  Total Personal Quota:
                </span>
                {` 300.000 ${currencey[0]?.id}`}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              justifyContent='space-between'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='body2'>Summary</Typography>
                <SummarizeIcon fontSize='small' color='primary' />
              </Stack>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stepper orientation='vertical'>
              <Step>
                <StepLabel>Stake Date</StepLabel>
              </Step>
              <Step>
                <StepLabel>Value Date</StepLabel>
              </Step>
              <Step>
                <StepLabel>Interest Distribution Date</StepLabel>
              </Step>
            </Stepper>
            <Typography variant='body1' my={2}>
              Est. APR
            </Typography>
            {/* <Stepper orientation='vertical'>
              <Step>
                <StepLabel>Tier 1: 0-1,000</StepLabel>
              </Step>
              <Step>
                <StepLabel>Tier 2: 1,000-5,000</StepLabel>
              </Step>
              <Step>
                <StepLabel>{`Tier 3: > 5,000`}</StepLabel>
              </Step>
            </Stepper> */}

            <Box
              sx={{
                border: "1px solid #662ad1",
                p: 1,
                borderRadius: "4px",
                my: 2,
              }}>
              <Stack direction='row' alignItems='flex-start' spacing={1}>
                <InfoIcon color='primary' fontSize='small' />
                <Typography variant='body2'>
                  The APR is adjusted daily based on the on-chain staking
                  rewards, and the specific APR is subject to the page display
                  on the day.
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='flex-start' spacing={1}>
                <InfoIcon color='primary' fontSize='small' />
                <Typography variant='body2'>
                  APR does not mean the actual or predicted returns in fiat
                  currency.
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Checkbox defaultChecked size='small' sx={{ p: 0 }} />
                <Typography variant='body2'>
                  I have read and I agree to ...
                </Typography>
              </Stack>
            </Box>

            <Stack>
              <Button
                onClick={handleSubscribeModal}
                variant='contained'
                fullWidth>
                Confirm
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DefiModal;
