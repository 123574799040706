import { Modal, Divider, Typography, Box, Grid, Button } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "24px 4px",
  boxShadow: 24,
  p: 4,
};

const buttonStyle = {
  color: "text.primary",
  border: "none",
  "&:hover": {
    bgcolor: "primary.light",
    color: "text.primary",
    border: "none",
  },
};

const buttonStyleActive = {
  color: "text.primary",
  border: "none",
  bgcolor: "primary.light",
  "&:hover": {
    bgcolor: "primary.light",
    color: "text.primary",
    border: "none",
  },
};

const CurrencyModal = ({ open, setOpen, setDataItem, dataItem, data }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setDataItem(event.currentTarget.id);
    localStorage.setItem("currency", event.currentTarget.id);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={modalStyle}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          Currency
        </Typography>
        <Divider color='#662ad1' />
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          sx={{ p: 1 }}>
          {data.length &&
            data.map((item) => (
              <Grid item key={item.id}>
                <Button
                  variant='outlined'
                  sx={dataItem === item.id ? buttonStyleActive : buttonStyle}
                  id={item.id}
                  value={item.name}
                  onClick={handleClick}
                  startIcon={item.icon}>
                  {item.title}
                </Button>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Modal>
  );
};

export default CurrencyModal;
