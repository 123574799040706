import { Button, Grid, Container } from "@mui/material";
import Image from "mui-image";
import Crypto from "assets/images/earn.webp";

const Earn = ({ largeScreen, navigate }) => (
  <Container maxWidth='lg'>
    <Grid
      container
      sx={{ p: 1 }}
      direction={largeScreen ? "column-reverse" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Grid item xs zeroMinWidth>
        <p>Put your crypto to work</p>
        <h1>Earn up to 14%</h1>
        <h1>Discover how it works?</h1>
        <p>Start earning simply by holding eligible assets</p>

        <Button
          fullWidth={largeScreen ? true : false}
          variant='contained'
          onClick={() => navigate("/earn")}>
          Start Earning
        </Button>
      </Grid>

      <Grid item xs zeroMinWidth>
        <Image src={Crypto} width={largeScreen ? "100%" : "65%"} duration={0} />
      </Grid>
    </Grid>
  </Container>
);

export default Earn;
