import { Divider, Stack, IconButton, ListItemButton } from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import CurrencyModal from "components/navbar/links/modal";
import { currencies } from "config/currencies.config";
import { links } from "config/list";
import { changeMode } from "features/theme/themeSlice";

const Links = ({
  largeScreen,
  currency,
  handleOpen,
  currencyTitle,
  isOpen,
  setOpen,
  setCurrency,
  themeMode,
  dispatch,
  ListOpen,
  isOpenList,
  navigate,
}) => (
  <>
    {largeScreen ? (
      <Stack direction='row' alignItems='center'>
        <Stack direction='row-reverse' alignItems='center'>
          {links.map((link) => (
            <ListItemButton key={link.id} onClick={() => navigate(link.href)}>
              {link.title}
            </ListItemButton>
          ))}
        </Stack>

        <Divider orientation='vertical' variant='middle' flexItem />

        <ListItemButton id={currency} onClick={handleOpen}>
          {currencyTitle}
        </ListItemButton>

        <Divider orientation='vertical' variant='middle' flexItem />

        <ListItemButton>English</ListItemButton>

        <Divider orientation='vertical' variant='middle' flexItem />

        <IconButton
          onClick={() => {
            dispatch(changeMode(themeMode === "dark" ? "light" : "dark"));
            localStorage.setItem(themeMode);
          }}>
          {themeMode === "dark" ? (
            <LightModeIcon color='primary' />
          ) : (
            <DarkModeIcon color='secondary' />
          )}
        </IconButton>

        {isOpen && (
          <CurrencyModal
            open={isOpen}
            setOpen={setOpen}
            setDataItem={setCurrency}
            dataItem={currency}
            data={currencies}
          />
        )}
      </Stack>
    ) : (
      <IconButton onClick={ListOpen}>
        {isOpenList ? (
          <CloseIcon color='secondary' />
        ) : (
          <MenuIcon color='secondary' />
        )}
      </IconButton>
    )}
  </>
);

export default Links;
