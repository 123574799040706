import { useState, useEffect } from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import * as api from "lib/api";

import StakingComponent from "./Staking";
import StakingTable from "./StakingTable";
import Calc from "./Calc";

const Staking = () => {
  const [data, setData] = useState(null);
  const [grow, setGrow] = useState(false);
  const [term, setTerm] = useState("");
  const [cro, setCro] = useState("");
  const [crypto, setCrypto] = useState("");
  const [open, setOpen] = useState(false);
  const [currencey, setCurrencey] = useState(null);
  const [SubscribeModal, setSubscribeModal] = useState(false);

  const handleTerm = (event) => {
    setTerm(event.target.value);
  };

  const handleCro = (event) => {
    setCro(event.target.value);
  };

  const handleCrypto = (event) => {
    setCrypto(event.target.value);
  };

  const subscribe = (event) => {
    setOpen(true);
    setCurrencey(data.filter((item) => item.id === event.target.id));
  };

  const handleSubscribeModal = () => {
    setSubscribeModal(true);
    setOpen(false);
  };

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));

  useEffect(() => {
    const coinsList = async () => {
      const response = await api.currencies();
      setData(response);
    };
    coinsList();
    setGrow(true);
    setCrypto("bitcoin");
  }, []);

  return (
    <>
      <StakingComponent data={data} largeScreen={largeScreen} grow={grow} />
      <Calc
        largeScreen={largeScreen}
        data={data}
        handleTerm={handleTerm}
        handleCro={handleCro}
        handleCrypto={handleCrypto}
        crypto={crypto}
        term={term}
        cro={cro}
      />
      <StakingTable
        data={data}
        grow={grow}
        subscribe={subscribe}
        setOpen={setOpen}
        handleSubscribeModal={handleSubscribeModal}
        currencey={currencey}
        open={open}
        SubscribeModal={SubscribeModal}
        setSubscribeModal={setSubscribeModal}
      />
    </>
  );
};

export default Staking;
