import {
  Container,
  Stack,
  Typography,
  TextField,
  Divider,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import Image from "mui-image";

import { styled } from "@mui/material/styles";

const TopDiv = styled("div")(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.background.default})`,
  width: "100%",
  height: "3px",
  borderRadius: "2px",
  marginTop: "2.4rem",
}));

const BottomDiv = styled("div")(({ theme }) => ({
  background: `linear-gradient(to left, ${theme.palette.primary.main}, ${theme.palette.background.default})`,
  width: "100%",
  height: "3px",
  borderRadius: "2px",
  marginTop: "2.4rem",
}));

const Calc = ({
  largeScreen,
  data,
  term,
  handleTerm,
  handleCro,
  cro,
  crypto,
  handleCrypto,
}) => (
  <Container maxWidth='lg' sx={{ p: 6 }}>
    <Typography
      variant='h3'
      sx={{ textAlign: "center", my: 10, fontWeight: "bold" }}>
      Calculate Your Crypto Earnings
    </Typography>
    <TopDiv />
    <Stack
      direction={largeScreen ? "column" : "row"}
      alignItems='center'
      justifyContent='space-between'
      p={4}
      spacing={3}
      divider={
        <Divider
          orientation={largeScreen ? "horizontal" : "vertical"}
          flexItem
        />
      }>
      <FormControl fullWidth>
        <InputLabel id='TOKEN-select-label'>TOKEN</InputLabel>
        <Select
          id='TOKEN-select'
          label='TOKEN'
          onChange={handleCrypto}
          value={crypto}>
          {data &&
            data.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <Stack direction='row' spacing={1}>
                  <Image src={option.image} width='25px' duration={0} />
                  <Typography sx={{ textTransform: "uppercase" }}>
                    {option.id}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <TextField label='AMOUNT' defaultValue='$5000' fullWidth />

      <FormControl fullWidth>
        <InputLabel id='CRO-STAKE-select-label'>CRO STAKE</InputLabel>
        <Select
          id='CRO-select'
          label='CRO STAKE'
          onChange={handleCro}
          value={cro}>
          <MenuItem key={`Less than 4,000`} value={`Less than 4,000`}>
            Less than 4,000
          </MenuItem>
          <MenuItem key={`4,000 to 40,000`} value={`4,000 to 40,000`}>
            4,000 to 40,000
          </MenuItem>
          <MenuItem key={`40,000 or more`} value={`40,000 or more`}>
            40,000 or more
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id='TERM-select-label'>TERM</InputLabel>
        <Select
          id='TERM-select'
          label='TERM'
          onChange={handleTerm}
          value={term}>
          <MenuItem key={"Flexible"} value={"Flexible"}>
            Flexible
          </MenuItem>
          <MenuItem key={"1 Month"} value={"1 Month"}>
            1 Month
          </MenuItem>
          <MenuItem key={"3 Months"} value={"3 Months"}>
            3 Months
          </MenuItem>
        </Select>
      </FormControl>
    </Stack>

    <Grid container>
      <Grid item xs>
        <Stack direction='column' alignItems='center' justifyContent='center'>
          <Typography
            variant='body1'
            sx={{ fontSize: "32px", fontWeight: "600" }}>
            Earn
          </Typography>
          <Typography
            variant='h1'
            sx={{ fontWeight: "bold", color: "#339fc3" }}>
            $970.000
          </Typography>
          <Typography
            variant='body1'
            sx={{ fontSize: largeScreen ? "20px" : "24px", fontWeight: "600" }}>
            {`Annual in `}
            <span style={{ textTransform: "uppercase" }}>{crypto}</span>
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs>
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          my={largeScreen ? 4 : 0}>
          <Typography
            variant='h2'
            sx={{ fontWeight: "bold", color: "#339fc3" }}>
            5.20%
          </Typography>
          <Typography
            variant='body1'
            sx={{ fontSize: largeScreen ? "20px" : "24px", fontWeight: "600" }}>
            Optimised rewards
          </Typography>
        </Stack>
      </Grid>
    </Grid>
    <Stack direction='column' alignItems='center' justifyContent='center'>
      <Typography variant='h2' sx={{ fontWeight: "bold", color: "#339fc3" }}>
        8.00
      </Typography>
      <Typography
        variant='body1'
        sx={{ fontSize: largeScreen ? "20px" : "24px", fontWeight: "600" }}>
        Rewards paid weekly
      </Typography>
    </Stack>
    <BottomDiv />
  </Container>
);

export default Calc;
