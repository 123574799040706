import Hero from "./hero";
import LatestNews from "./latest-news";
import Recources from "./resources";
import AllNews from "./all-news";
import { TickerTape } from "react-ts-tradingview-widgets";
import { useSelector } from "react-redux";

const styles = {
  parent: {
    display: "none",
  },
};

const MarketNews = () => {
  const themeMode = useSelector((state) => state.theme.value);
  return (
    <>
      <Hero />
      <TickerTape
        colorTheme={themeMode === "light" ? "light" : "dark"}
        copyrightStyles={styles}
      />
      <LatestNews />
      <AllNews />
      <Recources />
    </>
  );
};

export default MarketNews;
