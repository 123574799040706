import { Button, Grid, Container } from "@mui/material";
import Image from "mui-image";
import Ethereum from "assets/images/ethereum.png";

const Hero = ({ largeScreen, handleModal }) => (
  <Container maxWidth='lg'>
    <Grid
      alignItems='center'
      container
      sx={{ p: largeScreen ? 2 : 6 }}
      direction={largeScreen ? "column-reverse" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Grid item xs zeroMinWidth>
        <p>Stake your ETH in one click and easily start generating yields</p>
        <h1>Cryptostein Staking - ETH 2.0</h1>
        <h1>Passive earnings, up to 5.20%.</h1>
        <h1>Earn rewards</h1>
        <p>Staking is a public good for the Ethereum ecosystem</p>
        <Button
          onClick={handleModal}
          fullWidth={largeScreen ? true : false}
          variant='contained'>
          Stake Now
        </Button>
      </Grid>

      <Grid item xs zeroMinWidth>
        <Image
          src={Ethereum}
          width={largeScreen ? "100%" : "80%"}
          duration={0}
        />
      </Grid>
    </Grid>
  </Container>
);

export default Hero;
