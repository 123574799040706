import React from "react";

import Hero from "./hero";
import Markets from "./markets";
import MobileApp from "./mobileapp";
import Earn from "./earn";
import CryptoCurrencyMarket from "./crypto-market";

const Home = () => (
  <>
    <Hero />
    <Markets />
    <MobileApp />
    <Earn />
    <CryptoCurrencyMarket />
  </>
);

export default Home;
