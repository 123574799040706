import {
  Button,
  Container,
  Stack,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Table,
  Slide,
} from "@mui/material";
import Image from "mui-image";

import EarnModal from "./EarnModal";
import SubscribeModalComponent from "./SubscribeModal";

const StakingTable = ({
  data,
  grow,
  subscribe,
  open,
  currencey,
  setOpen,
  handleSubscribeModal,
  SubscribeModal,
  setSubscribeModal,
}) => (
  <Container maxWidth='lg' sx={{ p: 6 }}>
    <Typography
      variant='h3'
      sx={{ textAlign: "center", my: 10, fontWeight: "bold" }}>
      Compare Reward Rates
    </Typography>

    <TableContainer>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell>Coin</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Est.APR</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((item, index) => (
              <Slide
                key={item.id}
                in={grow}
                direction='up'
                style={{ transformOrigin: "0 0 0" }}
                {...(grow ? { timeout: index * 1000 } : {})}>
                <TableRow>
                  <TableCell>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Image src={item.image} duration={0} width='40px' />
                      <Typography
                        variant='body1'
                        sx={{
                          fontWeight: "600",
                          fontSize: "16px",
                          textTransform: "uppercase",
                        }}>
                        {item.id}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>Simple Earn</TableCell>
                  <TableCell align='left'>
                    <Typography
                      variant='body1'
                      sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#339fc3",
                      }}>
                      {`${5 + index}%`}
                    </Typography>
                  </TableCell>
                  <TableCell>Flexible</TableCell>
                  <TableCell align='right'>
                    <Button
                      id={item.id}
                      onClick={subscribe}
                      variant='contained'
                      sx={{
                        "&.MuiButton-contained": {
                          borderRadius: "16px 4px",
                          padding: "7px 16px",
                        },
                      }}>
                      Subscribe
                    </Button>
                  </TableCell>
                </TableRow>
              </Slide>
            ))}
        </TableBody>
      </Table>
    </TableContainer>

    {open && (
      <EarnModal
        open={open}
        currencey={currencey}
        setOpen={setOpen}
        handleSubscribeModal={handleSubscribeModal}
      />
    )}
    {SubscribeModal && (
      <SubscribeModalComponent
        SubscribeModal={SubscribeModal}
        setSubscribeModal={setSubscribeModal}
        currencey={currencey}
      />
    )}
  </Container>
);

export default StakingTable;
