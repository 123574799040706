import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ListComponents from "./list";

const MobileList = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState("");
  const navigate = useNavigate();

  const handleClick = (e) => {
    setOpen(!open);
    setShow(e.currentTarget.id);
  };

  return (
    <ListComponents
      open={open}
      show={show}
      handleClick={handleClick}
      navigate={navigate}
    />
  );
};

export default MobileList;
