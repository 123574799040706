import axios from "axios";

const currenciesResource =
  "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=6&page=1&sparkline=false";

const newsResource =
  "https://newsdata.io/api/1/news?apikey=pub_11371a5bb012d77932ce74572d38808f8a984&q=cryptocurrency&country=us&language=en&category=business,technology";

export const news = async () => {
  try {
    const response = await axios.get(newsResource);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const currencies = async () => {
  try {
    const response = await axios.get(currenciesResource);
    return response.data;
  } catch (err) {
    return null;
  }
};

export const trading = async (currency) => {
  try {
    const response = await axios.get(
      "https://api.binance.com/api/v3/klines?symbol=ETHUSDT&interval=1d&limit=1000"
    );
    return response.data;
  } catch (err) {
    return null;
  }
};
