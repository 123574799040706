import logo from "assets/images/logo.png";
import { Avatar, Stack, Typography } from "@mui/material";

const Logo = () => {
  return (
    <Stack direction='row' width={220}>
      <Avatar src={logo} alt='' sx={{ width: 50, height: 50 }} />
      <Stack
        direction='column'
        width='100%'
        alignItems='center'
        justifyContent='center'>
        <Typography
          color='secondary'
          sx={{
            fontSize: 14.5,
            fontFamily: "stretch",
          }}>
          CRYPTOSTEIN
        </Typography>
        <Typography color='secondary' sx={{ fontSize: 8, fontFamily: "Arial" }}>
          I SEE CRYPTOSTEIN IN YOUR FUTURE!
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Logo;
