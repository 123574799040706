import { useMediaQuery, Drawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useList from "hooks/useList";
import Links from "./links";
import List from "./list";

const SmallScreen = () => {
  const theme = useTheme();
  const { isOpen, ListOpen } = useList();

  const largeScreen = useMediaQuery(theme.breakpoints.down("1500"));
  const mediumScreen = useMediaQuery(theme.breakpoints.down("1360"));

  return (
    <>
      {largeScreen && (
        <Drawer anchor='left' open={isOpen} onClose={ListOpen}>
          {mediumScreen && <List />}
          <Links />
        </Drawer>
      )}
    </>
  );
};

export default SmallScreen;
