import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import ChartComponent from "./chart";

const Chart = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));
  const themeMode = useSelector((state) => state.theme.value);

  return <ChartComponent themeMode={themeMode} largeScreen={largeScreen} />;
};

export default Chart;
