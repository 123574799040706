import {
  IconButton,
  Container,
  Link,
  Grid,
  Divider,
  Typography,
} from "@mui/material";
import { format } from "date-fns";

import { styled } from "@mui/material/styles";

import Logo from "components/logo";

const Div = styled("div")(({ theme }) => ({
  backgroundColor: "#181A20",
  width: "100%",
  padding: "2rem 0",
}));

const Bar = styled("div")(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.main}, #181A20)`,
  width: "100px",
  height: "2px",
  margin: "10px 0",
}));

const Footer = ({ largeScreen, footerLinks, footerIcons }) => (
  <Div>
    <Container maxWidth='lg'>
      <Grid
        justifyContent='space-between'
        container
        sx={{ py: 2 }}
        direction='row'>
        {!largeScreen && (
          <Grid item xs='auto'>
            <Logo />
            <Typography variant='body1' color='secondary' sx={{ my: 2 }}>
              A new way of learning finance
            </Typography>
          </Grid>
        )}

        {footerLinks.length &&
          footerLinks.map((item) => {
            return (
              <Grid
                container
                item
                xs='auto'
                key={item.id + item.title}
                direction='column'>
                <Typography
                  variant='subtitle1'
                  color='secondary'
                  sx={{ fontWeight: "600" }}>
                  {item.title}
                </Typography>
                <Bar />
                {item.links.map((link) => (
                  <Link
                    target='_blank'
                    rel='noreferrer'
                    color='secondary'
                    underline='none'
                    key={link.id + link.title}
                    href={link.href}>
                    <Typography variant='body1' color='secondary'>
                      {link.title}
                    </Typography>
                  </Link>
                ))}
              </Grid>
            );
          })}

        {!largeScreen &&
          footerIcons.length &&
          footerIcons.map((item) => {
            return (
              <Grid
                container
                item
                xs='auto'
                key={item.id + item.title}
                direction='column'>
                <Typography
                  variant='subtitle1'
                  color='secondary'
                  sx={{ fontWeight: "600" }}>
                  {item.title}
                </Typography>
                <Bar />
                {item.links.map((link, id) => (
                  <Grid xs='auto' item key={id}>
                    {link.map((icon) => (
                      <IconButton
                        color='secondary'
                        target='_blank'
                        rel='noreferrer'
                        key={icon.title + icon.id}
                        size='small'
                        href={icon.href}>
                        {icon.icon}
                      </IconButton>
                    ))}
                  </Grid>
                ))}
              </Grid>
            );
          })}
      </Grid>
      <Divider />
      <Typography
        variant='body2'
        color='secondary'
        paragraph
        align='center'
        sx={{ my: 3 }}>
        Cryptostein © {format(new Date(), "yyyy")}
      </Typography>
    </Container>
  </Div>
);

export default Footer;
