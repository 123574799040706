import {
  Modal,
  Divider,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  Stack,
  Link,
  Switch,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CloseIcon from "@mui/icons-material/Close";

import Image from "mui-image";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "24px 4px",
  boxShadow: 24,
  p: 3,
};

const EarnModal = ({ open, currencey, setOpen, handleSubscribeModal }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box sx={modalStyle}>
        <Grid container spacing={8}>
          <Grid item xs={7}>
            <Stack direction='row' alignItems='center' spacing={1} mb={2}>
              <Typography variant='h6' component='h2'>
                Subscribe
              </Typography>

              <Image src={currencey[0]?.image} duration={0} width='30px' />

              <Typography
                variant='h6'
                component='h2'
                sx={{ textTransform: "uppercase" }}>
                {currencey[0]?.id}
              </Typography>
            </Stack>
            <Divider />
            <Typography variant='body2' component='h2' mt={2}>
              {` Duration (Days)`}
            </Typography>
            <Box
              sx={{
                border: "1px solid #662ad1",
                p: 1,
                width: "70px",
                borderRadius: "4px",
                mt: 1,
              }}>
              <Typography variant='body2' component='h2'>
                Flexible
              </Typography>
            </Box>

            <Typography variant='body2' component='h2' mt={4}>
              {`Subscription amount`}
            </Typography>

            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              justifyContent='space-between'>
              <TextField defaultValue='$520.0008703' size='small' />
              <Image src={currencey[0]?.image} duration={0} width='40px' />
              <Typography variant='body1' sx={{ textTransform: "uppercase" }}>
                {currencey[0]?.id}
              </Typography>
            </Stack>

            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              spacing={2}>
              <Typography
                variant='body2'
                component='h2'
                sx={{ fontSize: "12px" }}>
                {`520.8 ${currencey[0]?.id} `}
                <span style={{ fontWeight: "bold" }}>Available</span>
              </Typography>
              <Link sx={{ cursor: "pointer", fontSize: "12px" }}>Buy Now</Link>
            </Stack>

            <Typography variant='body2' component='h2' mt={4}>
              {`Amount Limits`}
            </Typography>

            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography variant='body2'>
                <span style={{ fontWeight: "bold" }}>Minimum:</span>
                {` 0.1 ${currencey[0]?.id}`}
              </Typography>
              <Typography variant='body2'>
                <span style={{ fontWeight: "bold" }}>Available Quota:</span>
                {` 200.000 ${currencey[0]?.id}`}
              </Typography>
              <InfoIcon color='primary' fontSize='small' />
            </Stack>

            <Stack direction='row' alignItems='center' spacing={2} mt={6}>
              <Switch />
              <div>
                <Typography variant='h6' component='h2'>
                  {`Auto-Subscribe`}
                </Typography>
                <Typography variant='body2' component='h2' mt={2}>
                  {`The available balance in your spot wallet will be used daily at 02:00 and 20:00 to subscribe to Flexible`}
                </Typography>
              </div>
              <AutoModeIcon color='primary' sx={{ fontSize: 60 }} />
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={2}
              justifyContent='space-between'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography variant='body2'>Summary</Typography>
                <SummarizeIcon fontSize='small' color='primary' />
              </Stack>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stepper orientation='vertical'>
              <Step>
                <StepLabel>Subscription Date</StepLabel>
              </Step>
              <Step>
                <StepLabel>Value Date</StepLabel>
              </Step>
              <Step>
                <StepLabel>Interest Distribution Date</StepLabel>
              </Step>
            </Stepper>
            <Typography variant='body1' my={2}>
              APR Tier Rate
            </Typography>
            <Stepper orientation='vertical'>
              <Step>
                <StepLabel>Tier 1: 0-1,000</StepLabel>
              </Step>
              <Step>
                <StepLabel>Tier 2: 1,000-5,000</StepLabel>
              </Step>
              <Step>
                <StepLabel>{`Tier 3: > 5,000`}</StepLabel>
              </Step>
            </Stepper>

            <Box
              sx={{
                border: "1px solid #662ad1",
                p: 1,
                borderRadius: "4px",
                my: 2,
              }}>
              <Stack direction='row' alignItems='flex-start' spacing={1}>
                <InfoIcon color='primary' fontSize='small' />
                <Typography variant='body2'>
                  APR does not mean the actual or predicted returns in fiat
                  currency
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Checkbox defaultChecked size='small' sx={{ p: 0 }} />
                <Typography variant='body2'>
                  I have read and I agree to ...
                </Typography>
              </Stack>
            </Box>

            <Stack>
              <Button
                onClick={handleSubscribeModal}
                variant='contained'
                fullWidth>
                Confirm
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EarnModal;
