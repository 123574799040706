import {
  BsCurrencyDollar,
  BsCurrencyEuro,
  BsCurrencyYen,
} from "react-icons/bs";

export const currencies = [
  { id: "USD", title: "USD", icon: <BsCurrencyDollar /> },
  { id: "EUR", title: "EUR", icon: <BsCurrencyEuro /> },
  { id: "JPY", title: "JPY", icon: <BsCurrencyYen /> },
];
