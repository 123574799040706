import { useState } from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import HeroCopmponent from "./hero";
import EthereumModal from "./EthereumModal";

const Hero = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));

  const handleModal = () => {
    setOpen(true);
  };

  return (
    <>
      <HeroCopmponent largeScreen={largeScreen} handleModal={handleModal} />
      {<EthereumModal open={open} setOpen={setOpen} />}
    </>
  );
};

export default Hero;
