import {
  Container,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faq = ({ data }) => (
  <Container maxWidth='lg' sx={{ p: 6 }}>
    <h1>FAQ</h1>
    {data &&
      data.map((item) => (
        <Accordion key={item.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: "14px" }}>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
  </Container>
);

export default Faq;
