import Hero from "./hero";
import Process from "./process";
import FAQ from "components/faq";
import { ethfaq } from "components/faq/faq.config";

const Ethereum = () => {
  return (
    <>
      <Hero />
      <Process />
      <FAQ data={ethfaq} />
    </>
  );
};

export default Ethereum;
