import { Container, Typography, Stack, Link, Grid } from "@mui/material";
import Image from "mui-image";
import Crypto from "assets/images/latestnews.webp";

const LatestNews = ({ data, largeScreen }) => (
  <Container maxWidth='lg'>
    <Grid
      container
      spacing={4}
      sx={{ py: largeScreen ? 2 : 6 }}
      direction={largeScreen ? "column" : "row"}>
      <Grid item xs zeroMinWidth>
        <Image src={Crypto} duration={0} />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Typography variant='h6' sx={{ fontWeight: "600", mb: 2 }}>
          Latest News :
        </Typography>
        {data.length &&
          data.slice(0, 4).map((item, id) => (
            <Stack
              id={item.id}
              direction='row'
              spacing={1}
              sx={{ minHeight: "100px", mb: 4 }}>
              <Typography variant='h3' color='primary'>
                {id + 1}
              </Typography>
              <Stack
                direction='column'
                spacing={4}
                sx={{ width: "100%" }}
                justifyContent='space-between'>
                <Typography variant='Body2' sx={{ fontWeight: "400" }}>
                  {item.title}
                </Typography>

                <Link
                  target='_blank'
                  rel='noopener'
                  underline='hover'
                  color='primary'
                  href={item.link}
                  sx={{
                    alignSelf: "flex-end",
                  }}>{`Read more >`}</Link>
              </Stack>
            </Stack>
          ))}
      </Grid>
    </Grid>
  </Container>
);

export default LatestNews;
