import { Container, Stack, Grow, Typography } from "@mui/material";
import Image from "mui-image";

const Staking = ({ data, largeScreen, grow }) => (
  <Container maxWidth='lg' sx={{ p: 6 }}>
    <Typography
      variant='h3'
      sx={{ textAlign: "center", mb: 10, fontWeight: "bold" }}>
      Cryptocurrencies And Stablecoins Available
    </Typography>
    <Stack
      direction={largeScreen ? "column" : "row"}
      justifyContent='space-between'
      alignItems='center'
      spacing={2}>
      {data &&
        data.slice(0, 3).map((item, index) => (
          <Grow
            key={item.id}
            in={grow}
            style={{ transformOrigin: "0 0 0" }}
            {...(grow ? { timeout: index * 1000 } : {})}>
            <Stack
              direction='column'
              justifyContent='center'
              spacing={2}
              alignItems='center'>
              <Image src={item.image} duration={0} width='40%' />
              <Typography
                variant='body1'
                sx={{
                  fontSize: "32px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "#339fc3",
                }}>
                {item.symbol} up to
              </Typography>
              <Typography
                variant='body1'
                sx={{ fontSize: "90px", fontWeight: "bold" }}>
                {`${5 + index}%`}
              </Typography>
            </Stack>
          </Grow>
        ))}
    </Stack>
  </Container>
);

export default Staking;
