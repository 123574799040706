import { createContext, useState } from "react";

const ListContext = createContext(null);

export const ListProvider = ({ children }) => {
  const [isOpen, setOpen] = useState(false);

  const ListOpen = () => setOpen(!isOpen);

  return (
    <ListContext.Provider value={{ isOpen, ListOpen }}>
      {children}
    </ListContext.Provider>
  );
};

export default ListContext;
