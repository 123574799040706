import { Grid, Container, Stack, Link, Typography } from "@mui/material";
import Image from "mui-image";

import Mobile from "assets/images/download.png";
import Playstore from "assets/images/playstore.png";
import Appstore from "assets/images/appstore.png";

const MobileApp = ({ largeScreen }) => (
  <Container maxWidth='lg'>
    <Grid
      container
      sx={{ p: largeScreen ? 2 : 6 }}
      direction={largeScreen ? "column" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Grid item xs zeroMinWidth>
        <Image src={Mobile} duration={0} />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Typography variant='h6' color='primary' sx={{ fontWeight: "600" }}>
          DOWNLOAD THE APP
        </Typography>
        <h1>Your investment portfolio</h1>
        <h1>in your pocket!</h1>
        <p>Anytime, anywhere, grow your portfolio</p>
        <p>By earning up to 14%.</p>
        <p>Try the App free of charge for 14 days.</p>
        <Stack direction='row' spacing={4}>
          <Link href='/'>
            <Image src={Playstore} duration={0} />
          </Link>
          <Link href='/'>
            <Image src={Appstore} duration={0} />
          </Link>
        </Stack>
      </Grid>
    </Grid>
  </Container>
);

export default MobileApp;
