import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import { useSelector } from "react-redux";

import { light, dark } from "./assets/layout/theme";

import { CssBaseline } from "@mui/material";

import { routes } from "config/routes";

import Footer from "components/footer";

import "./App.css";

const router = createBrowserRouter(routes);

const App = () => {
  const themeMode = useSelector((state) => state.theme.value);

  return (
    <ThemeProvider theme={themeMode === "light" ? light : dark}>
      <CssBaseline />
      <RouterProvider router={router} />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
