import {
  ListItemButton,
  List,
  ListItemSecondaryAction,
  ListItemIcon,
  Button,
} from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import LanguageIcon from "@mui/icons-material/Language";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import CurrencyModal from "components/navbar/links/modal";

import { links } from "config/list";
import { currencies } from "config/currencies.config.js";
import { changeMode } from "features/theme/themeSlice";

const Links = ({
  currency,
  handleOpen,
  currencyTitle,
  isOpen,
  setOpen,
  setCurrency,
  dispatch,
  themeMode,
}) => (
  <List>
    <ListItemButton
      onClick={() => {
        dispatch(changeMode(themeMode === "dark" ? "light" : "dark"));
        localStorage.setItem(themeMode);
      }}>
      <ListItemIcon>
        <ColorLensIcon sx={{ color: "#a27bea" }} />
      </ListItemIcon>
      Theme
      <ListItemSecondaryAction>
        {themeMode === "dark" ? (
          <LightModeIcon color='primary' />
        ) : (
          <DarkModeIcon color='secondary' />
        )}
      </ListItemSecondaryAction>
    </ListItemButton>

    <ListItemButton id={currency} onClick={handleOpen}>
      <ListItemIcon>
        <MonetizationOnIcon sx={{ color: "#a27bea" }} />
      </ListItemIcon>
      {currencyTitle}
    </ListItemButton>

    <ListItemButton>
      <ListItemIcon>
        <LanguageIcon sx={{ color: "#a27bea" }} />
      </ListItemIcon>
      English
    </ListItemButton>

    {links.map((link) =>
      link.title === "Register" ? (
        <Button sx={{ my: 4 }} fullWidth variant='contained' key={link.id}>
          {link.title}
        </Button>
      ) : (
        <ListItemButton key={link.id}>
          <ListItemIcon>{link.icon}</ListItemIcon>
          {link.title}
        </ListItemButton>
      )
    )}

    {isOpen && (
      <CurrencyModal
        open={isOpen}
        setOpen={setOpen}
        setDataItem={setCurrency}
        dataItem={currency}
        data={currencies}
      />
    )}
  </List>
);

export default Links;
