import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { currencies } from "config/currencies.config.js";

import LinksComponent from "./links";

const Links = () => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.theme.value);

  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") === null
      ? "USD"
      : localStorage.getItem("currency")
  );

  const currencyTitle = currencies.find(({ id }) => id === currency)?.title;

  const handleOpen = () => setOpen(true);

  return (
    <LinksComponent
      currency={currency}
      handleOpen={handleOpen}
      currencyTitle={currencyTitle}
      isOpen={isOpen}
      setOpen={setOpen}
      setCurrency={setCurrency}
      dispatch={dispatch}
      themeMode={themeMode}
    />
  );
};

export default Links;
