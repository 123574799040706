import { useState, useEffect } from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import * as api from "lib/api";

import AllNewsComponent from "./allnews";

const AllNews = () => {
  const [data, setData] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [newsId, setNewsId] = useState("");
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));

  const handleExpandClick = (e) => {
    setExpanded(!expanded);
    if (expanded) {
      setNewsId(e.currentTarget.id);
    } else {
      setNewsId("");
    }
  };

  useEffect(() => {
    const news = async () => {
      const response = await api.news();
      setData(response.results);
    };
    news();
  }, []);

  if (!data) return null;

  return (
    <AllNewsComponent
      largeScreen={largeScreen}
      data={data}
      handleExpandClick={handleExpandClick}
      expanded={expanded}
      newsId={newsId}
    />
  );
};

export default AllNews;
