import { useState, useEffect } from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import * as api from "lib/api";

import LatestNewsComponent from "./latestnews";

const LatestNews = () => {
  const [data, setData] = useState(null);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));

  useEffect(() => {
    const news = async () => {
      const response = await api.news();
      setData(response.results);
    };
    news();
  }, []);

  if (!data) return null;

  return <LatestNewsComponent data={data} largeScreen={largeScreen} />;
};

export default LatestNews;
