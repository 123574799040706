import Hero from "./hero";
import DefiTable from "./defitable";
import FAQ from "components/faq";
import { defifaq } from "components/faq/faq.config";

const Defi = () => {
  return (
    <>
      <Hero />
      <DefiTable />
      <FAQ data={defifaq} />
    </>
  );
};

export default Defi;
