import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import ListComponent from "./list";

const ListItems = () => {
  const [listItemHover, setListItemHover] = useState("");
  const navigate = useNavigate();

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("1360"));

  const handleListItemEnter = (e) => setListItemHover(e.target.id);
  const handleListItemLeave = () => setListItemHover("");

  return (
    <ListComponent
      largeScreen={largeScreen}
      handleListItemEnter={handleListItemEnter}
      handleListItemLeave={handleListItemLeave}
      listItemHover={listItemHover}
      navigate={navigate}
    />
  );
};

export default ListItems;
