import { Container, Grid } from "@mui/material";
import Image from "mui-image";

import Defi from "assets/images/defi.png";

const Hero = ({ largeScreen }) => (
  <Container maxWidth='lg'>
    <Grid
      container
      alignItems='center'
      sx={{ p: largeScreen ? 2 : 6 }}
      direction={largeScreen ? "column-reverse" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Grid item xs zeroMinWidth>
        <p>Dedicated to increasing user staking income</p>
        <h1>Cryptostein DeFi Staking</h1>
        <h1>Works through smart contracts</h1>
        <p>
          DeFi staking aims at encouraging long-term participation in a
          blockchain network
        </p>
      </Grid>
      <Grid item xs zeroMinWidth>
        <Image src={Defi} duration={0} width={largeScreen ? "100%" : "80%"} />
      </Grid>
    </Grid>
  </Container>
);

export default Hero;
