import { Outlet } from "react-router-dom";
import { AppBar, Toolbar, Container } from "@mui/material";
import { styled } from "@mui/material/styles";

import Logo from "components/logo";

import ListItem from "./list";
import NavLinks from "./links";
import MobileList from "./smallscreen";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const Navbar = () => {
  return (
    <>
      <AppBar position='fixed' component='nav'>
        <Container maxWidth={false} sx={{ padding: "0" }}>
          <Toolbar>
            <Logo />
            <MobileList />
            <ListItem />
            <NavLinks />
          </Toolbar>
        </Container>
      </AppBar>
      <Offset />
      <Outlet />
    </>
  );
};

export default Navbar;
