import { Button, Grid, Container } from "@mui/material";
import Image from "mui-image";
import Crypto from "assets/images/globe.png";

import { styled } from "@mui/material/styles";

const Div = styled("div")(({ theme }) => ({
  background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.background.default})`,
  width: "2px",
  borderRadius: "2px",
  marginTop: "2.4rem",
}));

const Hero = ({ largeScreen, navigate }) => (
  <Container maxWidth='lg'>
    <Grid
      container
      sx={{ p: largeScreen ? 2 : 6 }}
      direction={largeScreen ? "column-reverse" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Div />
      <Grid item xs zeroMinWidth>
        <p>I See Cryptostein In Your Future!</p>
        <h1>Transforming your way</h1>
        <h1>Of investing</h1>
        <h1>Is all what you need</h1>
        <p>Buy, Trade and Learn</p>

        <Button
          fullWidth={largeScreen ? true : false}
          variant='contained'
          onClick={() => navigate("/trade")}>
          Start Trading
        </Button>
      </Grid>

      <Grid item xs zeroMinWidth>
        <Image src={Crypto} width='85%' duration={0} />
      </Grid>
    </Grid>
  </Container>
);

export default Hero;
