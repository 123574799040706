import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DefiTableComponent from "./DefiTable";

import * as api from "lib/api";

const DefiTable = () => {
  const [data, setData] = useState(null);
  const [grow, setGrow] = useState(false);
  const [crypto, setCrypto] = useState("");
  const [open, setOpen] = useState(false);
  const [currencey, setCurrencey] = useState(null);
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));

  const stake = (event) => {
    setOpen(true);
    setCurrencey(data.filter((item) => item.id === event.target.id));
  };

  useEffect(() => {
    const coinsList = async () => {
      const response = await api.currencies();
      setData(response);
    };
    coinsList();
    setGrow(true);
    setCrypto("bitcoin");
  }, []);

  return (
    <DefiTableComponent
      largeScreen={largeScreen}
      data={data}
      grow={grow}
      crypto={crypto}
      open={open}
      setOpen={setOpen}
      stake={stake}
      currencey={currencey}
    />
  );
};

export default DefiTable;
