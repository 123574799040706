import Hero from "./hero";
import Staking from "./staking";
import Faq from "components/faq";
import { earnfaq } from "components/faq/faq.config";

const Earn = () => {
  return (
    <>
      <Hero />
      <Staking />
      <Faq data={earnfaq} />
    </>
  );
};

export default Earn;
