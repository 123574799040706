import Home from "pages/home";
import MarketNews from "pages/market-news";
import Navbar from "components/navbar";
import Earn from "pages/earn";
import Ethereum from "pages/ethereum";
import Defi from "pages/defi";
import Trading from "pages/trading";
import Register from "pages/register";
import Login from "pages/login";
import { ListProvider } from "contexts/list";

export const routes = [
  {
    id: "navbar",
    path: "/",
    element: <ListProvider children={<Navbar />} />,
    children: [
      {
        id: "home",
        path: "/",
        element: <Home />,
      },
      {
        id: "news",
        path: "news",
        element: <MarketNews />,
      },
      {
        id: "earn",
        path: "earn",
        element: <Earn />,
      },
      {
        id: "ethereum",
        path: "ethereum",
        element: <Ethereum />,
      },
      {
        id: "defi",
        path: "defi",
        element: <Defi />,
      },
      {
        id: "trade",
        path: "trade",
        element: <Trading />,
      },
      {
        id: "register",
        path: "register",
        element: <Register />,
      },
      {
        id: "login",
        path: "login",
        element: <Login />,
      },
    ],
  },
];
