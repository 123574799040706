import { Container, Stack, Typography, Grid } from "@mui/material";
import { format } from "date-fns";

import Image from "mui-image";

import Newsglobe from "assets/images/newsmap.webp";

import { styled } from "@mui/material/styles";

const Div = styled("div")(({ theme }) => ({
  background: theme.palette.text.primary,
  width: "50%",
  height: "2px",
  margin: "10px 0",
}));

const MarketNews = ({ largeScreen }) => (
  <Container maxWidth='lg' sx={{ pb: 4 }}>
    <Stack
      sx={{ py: largeScreen ? 2 : 4 }}
      direction='column'
      alignItems='center'
      justifyContent='center'>
      <Typography variant='h3'>Crypto News</Typography>
      <Typography sx={{ fontStyle: "italic" }} variant='subtitle2'>
        {format(new Date(), "MMM dd, yyyy")}
      </Typography>
      <Div />
      <Typography sx={{ fontStyle: "italic" }} variant='subtitle1'>
        Worldwide Cryptocurrency News
      </Typography>
    </Stack>
    <Grid
      container
      direction={largeScreen ? "column-reverse" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Grid item xs zeroMinWidth>
        <h1>Breaking crypto news</h1>
        <h1>See top stories on</h1>
        <h1>Bitcoin, Ethereum & more</h1>
      </Grid>

      <Grid item xs zeroMinWidth>
        <Image src={Newsglobe} width='85%' duration={0} />
      </Grid>
    </Grid>
  </Container>
);

export default MarketNews;
