import {
  Modal,
  Typography,
  Box,
  Button,
  Stack,
  Switch,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EastIcon from "@mui/icons-material/East";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "24px 4px",
  boxShadow: 24,
  p: 3,
};

const SubscribeModal = ({ setSubscribeModal, SubscribeModal, currencey }) => {
  const handleClose = () => {
    setSubscribeModal(false);
  };

  return (
    <Modal open={SubscribeModal} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Stack direction='column' spacing={2} alignItems='center'>
          <CheckCircleIcon sx={{ fontSize: "80px", color: "green" }} />
          <Typography variant='h6'>Subscription Successful</Typography>
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          my={2}>
          <Typography variant='body1'>Subscription amount</Typography>
          <Typography variant='body1'>{`520.8000682 ${currencey[0].id}`}</Typography>
        </Stack>

        <Stepper orientation='vertical'>
          <Step>
            <StepLabel>Subscription Date</StepLabel>
          </Step>
          <Step>
            <StepLabel>Value Date</StepLabel>
          </Step>
          <Step>
            <StepLabel>Interest Distribution Date</StepLabel>
          </Step>
        </Stepper>

        <Stack direction='row' alignItems='center' spacing={2} my={2}>
          <Switch />
          <Typography variant='body2' component='h2' mt={2}>
            {`Auto-Subscribe Enjoy the benefits every day`}
          </Typography>
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          spacing={2}
          my={4}>
          <div>
            <Typography variant='h6'>Auto-Invest</Typography>
            <Typography variant='body2' component='h2' mt={2}>
              {`Buy crypto on autopilot and earn Flexible savings rewards`}
            </Typography>
          </div>
          <CurrencyExchangeIcon color='primary' sx={{ fontSize: "60px" }} />
          <EastIcon color='primary' sx={{ fontSize: "40px" }} />
        </Stack>

        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          spacing={2}
          my={2}>
          <Button
            onClick={handleClose}
            variant='conteined'
            fullWidth
            startIcon={<ArrowBackIcon />}>
            Back to Earn
          </Button>
          <Button variant='outlined' fullWidth>
            View Holding
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SubscribeModal;
