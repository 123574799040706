import {
  Grid,
  Box,
  Typography,
  Divider,
  Stack,
  Button,
  Paper,
} from "@mui/material";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

const styles = {
  parent: {
    display: "none",
  },
};

const Chart = ({ themeMode, largeScreen }) => (
  <Grid container sx={{ p: 0 }} direction={largeScreen ? "column" : "row"}>
    <Grid item xs={largeScreen ? 12 : 10} zeroMinWidth sx={{ p: 0.5 }}>
      <AdvancedRealTimeChart
        symbol='BTCUSD'
        theme={themeMode === "light" ? "light" : "dark"}
        height={900}
        width='100%'
        details={largeScreen ? false : true}
        watchlist={
          largeScreen
            ? []
            : [
                ["BTCUSD"],
                ["ETHUSD"],
                ["BNBUSD"],
                ["USDTUSD"],
                ["XRPUSD"],
                ["USDCUSD"],
              ]
        }
        copyrightStyles={styles}
      />
    </Grid>
    <Grid item xs zeroMinWidth sx={{ py: 0.5, pr: 0.5 }}>
      <Box
        sx={{
          backgroundColor: themeMode === "light" ? "#fff" : "#131722",
          border: "1px solid",
          borderColor: themeMode === "light" ? "#fbfcfc" : "#2a2e39",
          p: 1,
        }}
        height={518}
        width='100%'>
        <Stack
          direction='column'
          spacing={2}
          width='100%'
          justifyContent='center'>
          <Typography
            variant='Body2'
            sx={{
              fontSize: "12px",
              color: themeMode === "light" ? "#000" : "#d1d4d6",
            }}>
            Available: 0.000000000 USDT
          </Typography>
          <Paper width='100%'>
            <Stack direction='row' justifyContent='space-between' p={1}>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                Price
              </Typography>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                17424.18
              </Typography>
            </Stack>
          </Paper>
          <Paper width='100%'>
            <Stack direction='row' justifyContent='space-between' p={1}>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                Amount
              </Typography>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                BTC
              </Typography>
            </Stack>
          </Paper>
          <Paper width='100%'>
            <Stack direction='row' justifyContent='space-between' p={1}>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                Total
              </Typography>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                USDT
              </Typography>
            </Stack>
          </Paper>
          <Button variant='outlined' color='success' fullWidth>
            Buy
          </Button>
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack
          direction='column'
          spacing={2}
          width='100%'
          justifyContent='center'>
          <Typography
            variant='Body2'
            sx={{
              fontSize: "12px",
              color: themeMode === "light" ? "#000" : "#d1d4d6",
            }}>
            Available: 0.000000000 USDT
          </Typography>
          <Paper width='100%'>
            <Stack direction='row' justifyContent='space-between' p={1}>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                Price
              </Typography>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                17424.18
              </Typography>
            </Stack>
          </Paper>
          <Paper width='100%'>
            <Stack direction='row' justifyContent='space-between' p={1}>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                Amount
              </Typography>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                BTC
              </Typography>
            </Stack>
          </Paper>
          <Paper width='100%'>
            <Stack direction='row' justifyContent='space-between' p={1}>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                Total
              </Typography>
              <Typography
                variant='Body2'
                sx={{
                  fontWeight: "600",
                  color: "#7b849b",
                  fontSize: "13px",
                }}>
                USDT
              </Typography>
            </Stack>
          </Paper>
          <Button variant='outlined' color='error' fullWidth>
            Sell
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          backgroundColor: themeMode === "light" ? "#fff" : "#131722",
          border: "1px solid",
          borderColor: themeMode === "light" ? "#fbfcfc" : "#2a2e39",
          mt: 0.5,
          p: 1,
        }}
        height={378}
        width='100%'>
        <Typography
          sx={{
            fontWeight: "bold",
            color: themeMode === "light" ? "#000" : "#d1d4d6",
            mb: 4,
          }}>
          Wallet Details
        </Typography>
        <Stack direction='column' spacing={1}>
          <Typography
            variant='Body2'
            sx={{
              fontWeight: "600",
              color: "#7b849b",
              fontSize: "13px",
            }}>
            Wallet Balance -
          </Typography>
          <Typography
            variant='Body2'
            sx={{
              fontWeight: "600",
              color: "#7b849b",
              fontSize: "13px",
            }}>
            Unrealized PnL -
          </Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction='column' spacing={1}>
          <Typography
            variant='Body2'
            sx={{
              fontWeight: "600",
              color: "#7b849b",
              fontSize: "13px",
            }}>
            Available Margin -
          </Typography>
          <Typography
            variant='Body2'
            sx={{
              fontWeight: "600",
              color: "#7b849b",
              fontSize: "13px",
            }}>
            Margin Balance -
          </Typography>
          <Typography
            variant='Body2'
            sx={{
              fontWeight: "600",
              color: "#7b849b",
              fontSize: "13px",
            }}>
            Initial Margin -
          </Typography>
          <Typography
            variant='Body2'
            sx={{
              fontWeight: "600",
              color: "#7b849b",
              fontSize: "13px",
            }}>
            Maintenance Margin
          </Typography>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Stack direction='column' spacing={1}>
          <Typography
            variant='Body2'
            sx={{
              fontWeight: "600",
              color: "#7b849b",
              fontSize: "13px",
            }}>
            Effective Leverage -
          </Typography>
          <Typography
            variant='Body2'
            sx={{
              fontWeight: "600",
              color: "#7b849b",
              fontSize: "13px",
            }}>
            Exposure Limit -
          </Typography>
        </Stack>
      </Box>
    </Grid>
  </Grid>
);

export default Chart;
