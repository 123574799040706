import {
  Button,
  Grid,
  Container,
  Paper,
  Stack,
  Chip,
  Zoom,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Image from "mui-image";

const cardStyle = {
  borderRadius: "24px 4px",
  backgroundColor: "theme.palette.background.default",
  width: "165px",
  height: "160px",
  cursor: "pointer",
  margin: "0 auto",
};

const Markets = ({ largeScreen, data, grow }) => (
  <Container maxWidth='lg'>
    <Grid
      container
      sx={{ p: largeScreen ? 2 : 6 }}
      direction={largeScreen ? "column-reverse" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Grid item xs={6} zeroMinWidth>
        <p>Find out which are the best cryptos smartly</p>
        <h1>Bitcoin or Ethereum?</h1>
        <h1>Tether or another?</h1>
        <p>through their trends and invest</p>

        <Button fullWidth={largeScreen ? true : false} variant='contained'>
          See more coins
        </Button>
      </Grid>
      {largeScreen || (
        <Grid
          container
          item
          xs={6}
          zeroMinWidth
          spacing={3}
          columns={{ xs: 4, sm: 8, md: 12 }}>
          {data.length &&
            data.map((item, index) => (
              <Grid key={item.id} item xs={4} sm={4} md={4} zeroMinWidth>
                <Zoom
                  in={grow}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(grow ? { timeout: index * 1000 } : {})}>
                  <Paper sx={cardStyle} elevation={2}>
                    <Stack
                      direction='column'
                      justifyContent='center'
                      alignItems='center'
                      spacing={0.5}
                      sx={{ p: 1 }}>
                      <Image
                        src={item.image}
                        width={50}
                        height={50}
                        duration={0}
                      />
                      <span>{item.name}</span>
                      <span>${item.current_price}</span>
                      {item.price_change_percentage_24h < 0 ? (
                        <Chip
                          variant='outlined'
                          color='error'
                          label={`${item.price_change_percentage_24h.toFixed(
                            2
                          )}%`}
                          icon={<ArrowDownwardIcon />}
                        />
                      ) : (
                        <Chip
                          variant='outlined'
                          color='success'
                          label={`${item.price_change_percentage_24h.toFixed(
                            2
                          )}%`}
                          icon={<ArrowUpwardIcon />}
                        />
                      )}
                    </Stack>
                  </Paper>
                </Zoom>
              </Grid>
            ))}
        </Grid>
      )}
    </Grid>
  </Container>
);

export default Markets;
