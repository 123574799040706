import { useState } from "react";
import useList from "hooks/useList";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { currencies } from "config/currencies.config";

import LinksComponent from "./links";

const Links = () => {
  const [isOpen, setOpen] = useState(false);
  const { isOpen: isOpenList, ListOpen } = useList();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.theme.value);
  const navigate = useNavigate();

  const [currency, setCurrency] = useState(
    localStorage.getItem("currency") === null
      ? "USD"
      : localStorage.getItem("currency")
  );

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("1500"));
  const currencyTitle = currencies.find(({ id }) => id === currency)?.title;

  const handleOpen = () => setOpen(true);

  return (
    <LinksComponent
      largeScreen={largeScreen}
      currency={currency}
      handleOpen={handleOpen}
      currencyTitle={currencyTitle}
      isOpen={isOpen}
      setOpen={setOpen}
      setCurrency={setCurrency}
      ListOpen={ListOpen}
      isOpenList={isOpenList}
      dispatch={dispatch}
      themeMode={themeMode}
      navigate={navigate}
    />
  );
};

export default Links;
