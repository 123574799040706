import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LayersIcon from "@mui/icons-material/Layers";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DownloadIcon from "@mui/icons-material/Download";
import LoginIcon from "@mui/icons-material/Login";
import { SiBlockchaindotcom } from "react-icons/si";
import { FaEthereum } from "react-icons/fa";

export const list = [
  { id: "1", title: "Home", icon: <HomeIcon />, href: "/" },
  { id: "2", title: "Marketplace", icon: <BarChartIcon /> },
  { id: "3", title: "Trade", icon: <CurrencyExchangeIcon />, href: "/trade" },
  {
    id: "4",
    title: "Earn",
    sublist: [
      {
        id: "1",
        title: "Earn",
        subtitle: "Start Your Investment",
        icon: <MonetizationOnIcon color='primary' />,
        href: "/earn",
      },
      {
        id: "2",
        title: "DeFi Staking",
        subtitle: "Earn Passive Income",
        icon: <LayersIcon color='primary' />,
        href: "/defi",
      },
      {
        id: "3",
        title: "ETH 2.0 Staking",
        subtitle: "Paid daily",
        icon: <FaEthereum style={{ color: "#662ad1", fontSize: "20px" }} />,
        href: "/ethereum",
      },
    ],
    icon: <AttachMoneyIcon />,
  },
  { id: "5", title: "NFT", icon: <SiBlockchaindotcom /> },
  {
    id: "6",
    title: "News",
    icon: <NewspaperIcon />,
    href: "/news",
  },
];

export const links = [
  {
    id: "1",
    title: "Downloads",
    href: "#",
    icon: <DownloadIcon sx={{ color: "#a27bea" }} />,
  },
  {
    id: "2",
    title: "Login",
    icon: <LoginIcon sx={{ color: "#a27bea" }} />,
    href: "/login",
  },
  { id: "3", title: "Register", href: "/register" },
];
