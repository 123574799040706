import { Container, Typography, Box } from "@mui/material";
import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";
import { useSelector } from "react-redux";

const styles = {
  parent: {
    display: "none",
  },
};

const CryproMarket = () => {
  const themeMode = useSelector((state) => state.theme.value);
  return (
    <Container maxWidth='lg'>
      <Box sx={{ py: 6 }}>
        <Typography
          variant='h4'
          sx={{ my: 5, fontWeight: "bold", textAlign: "center" }}>
          Cryptocurrency Market
        </Typography>
        <CryptoCurrencyMarket
          isTransparent
          copyrightStyles={styles}
          colorTheme={themeMode === "light" ? "light" : "dark"}
          width='100%'
        />
      </Box>
    </Container>
  );
};

export default CryproMarket;
