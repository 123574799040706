import {
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  Button,
  Stack,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import Image from "mui-image";
import Earn from "assets/images/register.webp";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Register = ({
  largeScreen,
  handleClickShowPassword,
  showPassword,
  navigate,
}) => (
  <Container maxWidth='lg'>
    <Grid
      container
      sx={{ p: largeScreen ? 2 : 6 }}
      direction={largeScreen ? "column-reverse" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Grid item xs zeroMinWidth>
        <Typography variant='h3'>Create an account</Typography>
        <Typography variant='body2' sx={{ my: 4 }}>
          {`Required fields have an asterisk: *`}
        </Typography>
        <FormControl fullWidth>
          <Stack direction='column' spacing={6}>
            <Stack
              direction={largeScreen ? "column" : "row"}
              spacing={largeScreen ? 6 : 2}>
              <TextField label='First Name' fullWidth required />
              <TextField label='Last Name' fullWidth required />
            </Stack>

            <TextField label='Email' fullWidth required />
            <FormControl variant='outlined' required fullWidth>
              <InputLabel htmlFor='outlined-adornment-password'>
                Password
              </InputLabel>
              <OutlinedInput
                id='outlined-adornment-password'
                label='Password'
                type={showPassword ? "password" : "text"}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      edge='end'>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Stack direction='row' alignItems='flex-start' spacing={1}>
              <Checkbox size='small' sx={{ p: 0 }} />
              <Typography variant='body2'>
                I certify that I am 18 years of age or older, I agree to the
                <Link href='#' underline='none'>
                  {` User Agreement`}
                </Link>
                , and I have read the{" "}
                <Link href='#' underline='none'>
                  {` Privacy Policy.`}
                </Link>
              </Typography>
            </Stack>

            <Button fullWidth variant='contained'>
              Create free account
            </Button>
          </Stack>
        </FormControl>
        <Stack
          direction='row'
          spacing={0}
          sx={{ my: 4 }}
          justifyContent='center'
          alignItems='center'>
          <Button
            variant='text'
            color='primary'
            onClick={() => navigate("/login")}>
            Sign in
          </Button>
          <Typography variant='body2'>
            if you already have an account
          </Typography>
        </Stack>
      </Grid>

      <Grid item xs zeroMinWidth>
        <Image src={Earn} duration={0} />
      </Grid>
    </Grid>
  </Container>
);

export default Register;
