import {
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  Button,
  Stack,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import QRCode from "react-qr-code";

const Login = ({
  largeScreen,
  handleClickShowPassword,
  showPassword,
  navigate,
}) => (
  <Container maxWidth='lg'>
    <Grid
      container
      sx={{ p: largeScreen ? 2 : 6 }}
      direction={largeScreen ? "column-reverse" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Grid item xs zeroMinWidth>
        <Typography variant='h3'>Cryptostein Sign in</Typography>
        <Typography variant='body2' sx={{ my: 4 }}>
          {`Required fields have an asterisk: *`}
        </Typography>
        <FormControl fullWidth>
          <Stack direction='column' spacing={6}>
            <TextField label='Email' fullWidth required />
            <FormControl variant='outlined' required fullWidth>
              <InputLabel htmlFor='outlined-adornment-password'>
                Password
              </InputLabel>
              <OutlinedInput
                id='outlined-adornment-password'
                label='Password'
                type={showPassword ? "password" : "text"}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      edge='end'>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button fullWidth variant='contained'>
              Sign in
            </Button>
          </Stack>
        </FormControl>
        <Stack
          direction='row'
          spacing={0}
          sx={{ my: 4 }}
          justifyContent='center'
          alignItems='center'>
          <Button
            variant='text'
            color='primary'
            onClick={() => navigate("/register")}>
            Sign up
          </Button>
          <Typography variant='body2'>if you don't have an account</Typography>
        </Stack>
      </Grid>

      <Grid item xs zeroMinWidth>
        <Stack justifyContent='center' alignItems='center'>
          <Typography variant='h6' sx={{ my: 6 }}>
            Sign in with QR code
          </Typography>

          <Stack
            sx={{
              background: "white",
              p: 1,
              width: 218,
              borderRadius: 2,
              border: "2px solid #662ad1",
              justifySelf: "center",
            }}>
            <QRCode value='Cryptostein' size={200} />
          </Stack>
          <Typography variant='body2' sx={{ my: 6, textAlign: "center" }}>
            Scan this code with the Cryptostein <br /> mobile app to log in
            instantly.
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  </Container>
);

export default Login;
