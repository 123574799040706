import { Container, Grid, Typography } from "@mui/material";
import Image from "mui-image";
import Earn from "assets/images/earn.png";

const Hero = ({ largeScreen }) => (
  <Container maxWidth='lg'>
    <Grid
      container
      sx={{ p: largeScreen ? 2 : 6 }}
      direction={largeScreen ? "column-reverse" : "row"}
      spacing={largeScreen ? 4 : 2}>
      <Grid item xs zeroMinWidth>
        <h1>Cryptostein Earn</h1>
        <h2>Earn up to </h2>

        <Typography variant='body1' color='primary' sx={{ fontSize: "150px" }}>
          14%
        </Typography>

        <h2>On your crypto</h2>
        <p>Search popular coins and start earning.</p>
      </Grid>

      <Grid item xs zeroMinWidth>
        <Image src={Earn} duration={0} />
      </Grid>
    </Grid>
  </Container>
);
export default Hero;
