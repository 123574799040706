import {
  Container,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  StepContent,
} from "@mui/material";

import { styled } from "@mui/material/styles";

const Div = styled("div")(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.background.default})`,
  width: "100%",
  height: "2px",
  borderRadius: "2px",
  margin: "16px 0",
}));

const Process = ({ largeScreen }) => {
  return (
    <Container maxWidth='lg'>
      <Box sx={{ p: largeScreen ? 2 : 6 }}>
        <Typography variant='h5' sx={{ fontWeight: "bold" }}>
          Process
        </Typography>
        <Div />
        <Stepper orientation='vertical'>
          <Step active color='red'>
            <StepLabel>Stake ETH</StepLabel>
            <StepContent>
              <Typography my={2}>
                Stake your ETH in one click and easily start generating yields,
                with token quantity protection and slashing coverage. You will
                get BETH as the tokenized representation of your staked ETH in a
                1:1 ratio, allowing you to take advantage of the liquidity as
                well as enjoying the rewards of ETH staking.
              </Typography>
            </StepContent>
          </Step>
          <Step active>
            <StepLabel>Rewards Distribution</StepLabel>
            <StepContent>
              <Typography my={2}>
                We regularly distribute on-chain rewards to all participants
                based on their BETH position. The on-chain rewards will be
                distributed in the form of BETH to users' Spot accounts.
              </Typography>
            </StepContent>
          </Step>
          <Step expanded>
            <StepLabel
              optional={
                <Typography variant='caption'>To be confirmed.</Typography>
              }>
              Redeem ETH
            </StepLabel>
            <StepContent>
              <Typography my={2}>
                You can redeem your ETH once the first major upgrade named
                Shanghai Upgrade enables staking withdrawals, which happens at
                least 6-12 months following the Merge. This is due to staking
                withdrawals being planned for the Shanghai upgrade, however are
                not yet enabled with the Merge. In the mean time, you can swap
                BETH to ETH on a 1:1 basis and receive the amount equivalent to
                your BETH balance in your Spot Wallet. Additionally, the BETH
                bought from market and received from staking ETH will be treated
                equally.
              </Typography>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
    </Container>
  );
};

export default Process;
