import { Container, Typography, Stack, Link, Box } from "@mui/material";

const AllNews = ({ data, largeScreen }) => (
  <Container maxWidth='lg'>
    <Box sx={{ py: largeScreen ? 2 : 6 }}>
      <Typography variant='h6' sx={{ fontWeight: "600", mb: 2 }}>
        All News :
      </Typography>
      {data.length &&
        data.map((item, id) => (
          <Box
            id={id}
            sx={{
              minHeight: "100px",
              mb: 4,
              border: "1px solid #662ad1",
              p: 2,
              borderRadius: "4px",
              width: "100%",
            }}>
            <Stack
              direction='column'
              sx={{ width: "100%" }}
              spacing={4}
              justifyContent='space-between'>
              <Typography variant='Body2' sx={{ fontWeight: "bold" }}>
                {item.title}
              </Typography>
              <Typography variant='Body2' sx={{ fontWeight: "400" }}>
                {item.description}
              </Typography>

              <Link
                target='_blank'
                rel='noopener'
                underline='hover'
                color='primary'
                href={item.link}
                sx={{
                  alignSelf: "flex-end",
                }}>{`Read more >`}</Link>
            </Stack>
          </Box>
        ))}
    </Box>
  </Container>
);

export default AllNews;
