import {
  FaFacebook,
  FaGithub,
  FaLinkedin,
  FaTwitter,
  FaInstagramSquare,
  FaTiktok,
  FaMedium,
  FaYoutube,
  FaDiscord,
} from "react-icons/fa";

export const footerLinks = [
  {
    id: "1",
    title: "Products",
    links: [
      { id: "1", title: "Exchange", href: "/" },
      { id: "2", title: "Education", href: "/" },
      { id: "3", title: "Wallet", href: "/" },
      { id: "4", title: "PLEARN", href: "https://plearngame.netlify.app" },
      { id: "5", title: "ESGROW", href: "https://esgrow.netlify.app" },
    ],
  },
  {
    id: "2",
    title: "Company",
    links: [
      { id: "1", title: "About", href: "/" },
      { id: "2", title: "Information", href: "/" },
      { id: "3", title: "Legal", href: "/" },
      { id: "4", title: "Privacy", href: "/" },
    ],
  },
  {
    id: "3",
    title: "Support",
    links: [
      { id: "1", title: "Contact Us", href: "/" },
      { id: "2", title: "Chat", href: "/" },
      { id: "3", title: "Help Center", href: "/" },
      { id: "4", title: "FAQ", href: "/" },
    ],
  },
];

export const footerIcons = [
  {
    id: "1",
    title: "Social",
    links: [
      [
        {
          id: "1",
          title: "Facebook",
          icon: <FaFacebook />,
          href: "https://www.facebook.com/profile.php?id=100082673067170",
        },
        {
          id: "2",
          title: "Twitter",
          icon: <FaTwitter />,
          href: "https://twitter.com/CryptoStein2022",
        },
        {
          id: "3",
          title: "Linkedin",
          icon: <FaLinkedin />,
          href: "https://www.linkedin.com/company/cryptostein1/",
        },
      ],
      [
        {
          id: "4",
          title: "Github",
          icon: <FaGithub />,
          href: "https://github.com/CryptoStein",
        },
        {
          id: "5",
          title: "Instagram",
          icon: <FaInstagramSquare />,
          href: "https://www.instagram.com/cryptostein.official/",
        },
        {
          id: "6",
          title: "Tiktok",
          icon: <FaTiktok />,
          href: "https://www.tiktok.com/@cryptostein",
        },
      ],
      [
        {
          id: "7",
          title: "Medium",
          icon: <FaMedium />,
          href: "https://medium.com/@adm.cryptostein",
        },
        {
          id: "8",
          title: "Youtube",
          icon: <FaYoutube />,
          href: "https://www.youtube.com/channel/UCn5qOdVJDN4KIjPN9Po9BGg",
        },
        { id: "9", title: "Discord", icon: <FaDiscord />, href: "/" },
      ],
    ],
  },
];
