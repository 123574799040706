import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import MobileAppComponent from "./mobileapp";

const MobileApp = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));
  return <MobileAppComponent largeScreen={largeScreen} />;
};

export default MobileApp;
