import {
  List,
  ListItem,
  Stack,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Button,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { list } from "config/list";

const ListItems = ({
  largeScreen,
  handleListItemEnter,
  handleListItemLeave,
  listItemHover,
  navigate,
}) => (
  <>
    {largeScreen && (
      <List id='menu-appbar'>
        <Stack direction='row' alignItems='cente'>
          {list.map((item) => (
            <ListItem
              id={item.id}
              key={item.id}
              onMouseEnter={handleListItemEnter}
              onMouseLeave={handleListItemLeave}>
              <Button
                color='secondary'
                variant='text'
                onClick={() => navigate(item.href)}>
                {item.title}
              </Button>
              {item?.sublist &&
                (listItemHover !== item.id ? (
                  <ArrowDropDownIcon />
                ) : (
                  <>
                    <ArrowDropUpIcon />
                    <Paper
                      elevation={6}
                      sx={{
                        position: "absolute",
                        top: "40px",
                        maxWidth: "500px",
                        width: "320px",
                      }}>
                      <List>
                        {item.sublist.map((subitem) => (
                          <MenuItem
                            key={subitem.id}
                            onClick={() => navigate(subitem.href)}>
                            <ListItemIcon>{subitem.icon}</ListItemIcon>
                            <ListItemText
                              primary={subitem.title}
                              secondary={subitem.subtitle}
                            />
                          </MenuItem>
                        ))}
                      </List>
                    </Paper>
                  </>
                ))}
            </ListItem>
          ))}
        </Stack>
      </List>
    )}
  </>
);

export default ListItems;
