import {
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { list } from "config/list";

const ListItems = ({ open, show, handleClick, navigate }) => (
  <List component='nav'>
    {list.map((item) => (
      <div key={item.id}>
        <ListItemButton
          id={item.id}
          onClick={item?.sublist ? handleClick : () => navigate(item?.href)}>
          <ListItemIcon sx={{ color: "#a27bea" }}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} color='secondary' />
          {item?.sublist &&
            (open && show === item.id ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            ))}
        </ListItemButton>
        {item?.sublist && show === item.id && (
          <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {item?.sublist.map((subitem) => (
                <ListItemButton
                  key={subitem.id}
                  sx={{ pl: 4 }}
                  onClick={() => navigate(subitem.href)}>
                  <ListItemText
                    primaryTypographyProps={{
                      color: "#e0d3f8",
                    }}
                    primary={subitem.title}
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        )}
      </div>
    ))}
  </List>
);
export default ListItems;
