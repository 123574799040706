import { useState, useEffect } from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import * as api from "lib/api";

import ClipPath from "components/clip-path";

import MarketsComponent from "./markets";

const Markets = () => {
  const [data, setData] = useState(null);
  const [grow, setGrow] = useState(false);

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));

  useEffect(() => {
    const coinsList = async () => {
      const response = await api.currencies();
      setData(response);
    };
    coinsList();
    setGrow(true);
  }, []);

  return (
    <>
      {data && (
        <ClipPath
          id='shape-markets'
          children={
            <MarketsComponent
              largeScreen={largeScreen}
              data={data}
              grow={grow}
            />
          }
        />
      )}
    </>
  );
};

export default Markets;
