import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import HeroComponent from "./hero";

const Hero = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));
  return <HeroComponent largeScreen={largeScreen} />;
};

export default Hero;
