import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { footerLinks, footerIcons } from "./footer.config";

import FooterComponent from "./footer";

const Footer = () => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.down("1024"));
  return (
    <FooterComponent
      footerLinks={footerLinks}
      footerIcons={footerIcons}
      largeScreen={largeScreen}
    />
  );
};

export default Footer;
