import { styled } from "@mui/material/styles";

const Div = styled("div")(({ theme }) => ({
  clipPath: "polygon(0 0, 100% 10%, 100% 100%, 0% 90%)",
  backgroundColor: theme.palette.secondary.light,
  width: "100%",
  padding: "5rem 0",
}));

const ClipPath = ({ children }) => <Div>{children}</Div>;

export default ClipPath;
