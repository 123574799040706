import { Paper, Container, Typography, Stack } from "@mui/material";
import Image from "mui-image";
import Cointelegraph from "assets/images/cointelegraph.png";
import Wsj from "assets/images/wsj.png";
import Pcgamer from "assets/images/pcgamer.png";
import Bitcoin from "assets/images/bitcoinnews.png";
import Tradingview from "assets/images/tradingview.png";

const cardStyle = {
  borderRadius: "24px 4px",
  backgroundColor: "theme.palette.background.default",
  width: "165px",
  height: "140px",
};

const Resources = ({ largeScreen }) => (
  <Container maxWidth='lg'>
    <Typography
      variant='h3'
      sx={{ textAlign: "center", my: 10, fontWeight: "bold" }}>
      Resources
    </Typography>
    <Stack
      sx={{ p: largeScreen ? 2 : 6 }}
      alignItems='center'
      justifyContent='space-between'
      direction={largeScreen ? "column" : "row"}
      spacing={4}>
      <Paper sx={cardStyle} elevation={2}>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          sx={{ p: 1 }}>
          <Image src={Cointelegraph} width={80} duration={0} />
          <span>Cointelegraph</span>
        </Stack>
      </Paper>

      <Paper sx={cardStyle} elevation={2}>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={4.5}
          sx={{ p: 1 }}>
          <Image src={Wsj} width={100} duration={0} />
          <span>Wall street journal</span>
        </Stack>
      </Paper>

      <Paper sx={cardStyle} elevation={2}>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          sx={{ p: 1 }}>
          <Image src={Pcgamer} width={80} duration={0} />
          <span>Pc Gamer</span>
        </Stack>
      </Paper>

      <Paper sx={cardStyle} elevation={2}>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          sx={{ p: 1 }}>
          <Image src={Bitcoin} width={80} duration={0} />
          <span>Bitcoin</span>
        </Stack>
      </Paper>

      <Paper sx={cardStyle} elevation={2}>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={2}
          sx={{ p: 1 }}>
          <Image src={Tradingview} width={80} duration={0} />
          <span>Trading view</span>
        </Stack>
      </Paper>
    </Stack>
  </Container>
);

export default Resources;
